"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripHtml = exports.sanitizeHTML = exports.isEmpty = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _sanitizeHtml = _interopRequireDefault(require("sanitize-html"));
var sanitizeHTML = exports.sanitizeHTML = function sanitizeHTML(dirtyHtmlString) {
  return dirtyHtmlString ? (0, _sanitizeHtml["default"])(dirtyHtmlString) : '';
};
var strip_html_regex = /(<([^>]+)>|(&(nbsp|ensp|emsp|emsp13|numsp|puncsp|thinsp|hairsp[^;])+;)+)/gi;
var stripHtml = exports.stripHtml = function stripHtml() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return string ? string.replace(strip_html_regex, "") : string;
};
var isEmpty = exports.isEmpty = function isEmpty(value) {
  return value === '' || value === undefined || value === null;
};