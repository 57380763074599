"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateRanking = exports.isEmpty = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.values.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.array.index-of.js");
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _i18n = require("../../i18n");
var _excluded = ["name", "required"];
var isEmpty = exports.isEmpty = function isEmpty(value) {
  return value === '' || value === undefined || value === null;
};
var validateRanking = exports.validateRanking = function validateRanking(_ref) {
  var name = _ref.name,
    required = _ref.required,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value, allValues) {
    if (value === 'set_by_exclusive_input' || !isEmpty(allValues === null || allValues === void 0 ? void 0 : allValues["".concat(name, "_exclusive")])) return undefined;
    var answersToRank = rest.ranking_component_answers;
    if (value) {
      var rankedOptions = Object.values(value).filter(function (rankedOption) {
        return rankedOption !== '';
      }) || [];
      var minRankedOptions = rest.min_value;
      var maxRankedOptions = rest.max_value;
      var everyAnswerShouldBeRanked = false;
      if (isEmpty(minRankedOptions) && isEmpty(maxRankedOptions)) {
        everyAnswerShouldBeRanked = true;
      }

      // Optional and there is not ranked options
      if (!required && rankedOptions.length === 0) {
        return undefined;
      }
      if (!isEmpty(minRankedOptions) && !isEmpty(maxRankedOptions)) {
        if (rankedOptions.length < Number(minRankedOptions) || rankedOptions.length > Number(maxRankedOptions)) {
          return (0, _i18n.globalTranslate)('fields.ranking.requiredMinMaxOptions', {
            min: minRankedOptions,
            max: maxRankedOptions
          });
        }
      } else {
        // Else assuming user will answer, should follow the min
        if (!isEmpty(minRankedOptions) && rankedOptions.length < Number(minRankedOptions)) {
          return (0, _i18n.globalTranslate)('fields.ranking.requiredMinOptions', {
            min: minRankedOptions
          });
        }
        if (!isEmpty(maxRankedOptions) && rankedOptions.length > Number(maxRankedOptions)) {
          return (0, _i18n.globalTranslate)('fields.ranking.requiredMaxOptions', {
            max: maxRankedOptions
          });
        }
      }
      if (rankedOptions.filter(function (item, index) {
        return rankedOptions.indexOf(item) !== index;
      }) > 0) {
        return (0, _i18n.globalTranslate)('fields.ranking.nonRepeatedValues');
      }
      if (everyAnswerShouldBeRanked && answersToRank.length !== rankedOptions.length) {
        return (0, _i18n.globalTranslate)('fields.ranking.shouldRankAllOptions');
      }
      return undefined;
    }
    return undefined;
  };
};
var RankingValidations = function RankingValidations(props) {
  return [validateRanking(props)];
};
var _default = exports["default"] = RankingValidations;